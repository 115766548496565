/* eslint-disable complexity */
import React from 'react';
import { navigate } from 'gatsby';
import { Avatar, Button, Card, Descriptions, Modal, Tag } from 'antd';
import { css } from '@emotion/core';
import { UserOutlined, DeleteOutlined } from '@ant-design/icons';
import { categories, categoriesWork } from 'utils/helpers';
import { getUser } from 'utils/auth';
import { AppStateContext } from 'state/appstate';
import firebase from 'gatsby-plugin-firebase';
import { media } from 'utils/media';

const CardItem = (props) => {
  const { item, ...otherProps } = props;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [loadingDeleteUser, setLoadingDeleteUser] = React.useState(false);
  const [userData, setUserData] = React.useState();
  const user = getUser();
  const { appValue, getAppColors } = React.useContext(AppStateContext);

  const appColor = getAppColors();

  const deleteProject = (id) => {
    // console.log(id);
    setLoadingDeleteUser(true);
    firebase
      .firestore()
      .collection('projects')
      // .where('id', '==', id)
      .doc(id)
      .delete()
      // .get()
      .then(() => {
        setLoadingDeleteUser(false);
        if (typeof window !== 'undefined') {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!userData) {
        firebase
          .firestore()
          .collection('usersData')
          .where('uid', '==', item.user.uid)
          .get()
          .then((snapshot) => {
            const userDataFromApi = snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            });
            setUserData({
              ...userDataFromApi[0],
              tel: userDataFromApi[0].tel,
              address: userDataFromApi[0].address,
              age: userDataFromApi[0].age,
            });
          });
      }
    }
  });

  const openDeleteModel = () => {
    setModalOpen(true);
  };
  return (
    <Card
      css={css`
        margin: 20px;
        border-color: ${appColor};
        border-radius: 4px;
        border-width: ${otherProps.highlight === 'true' ? '4px' : '1px'};
      `}
      {...otherProps}
      title={
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              justify-content: start;
              align-items: center;
            `}
          >
            {item.user.photoURL ? (
              <Avatar
                css={css`
                  .ant-avatar-string {
                    position: unset;
                  }
                `}
                src={item.user.photoURL}
              />
            ) : (
              <Avatar icon={<UserOutlined />} />
            )}
            <span
              css={css`
                padding-left: 20px;
              `}
            >
              {item.user && item.user.displayName
                ? item.user.displayName
                : 'Anonymer Benutzer'}
            </span>
          </div>

          {item.user.uid == user.uid && (
            <div
              css={css`
                display: flex;
                position: relative;
                width: 100%;
                justify-content: end;
                align-items: end;
              `}
            >
              <Modal
                title="Projelt löschen"
                visible={modalOpen}
                onOk={() => {
                  deleteProject(item.id);
                }}
                footer={[
                  <Button
                    key="back"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Abbrechen
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={loadingDeleteUser}
                    onClick={() => {
                      deleteProject(item.id);
                    }}
                  >
                    Löschen
                  </Button>,
                ]}
              >
                <p>
                  M&ouml;chtest du das Projekt wirklich l&ouml;schen? Wir
                  k&ouml;nnen diese Aktion nicht wieder r&uuml;ckgängig machen.
                </p>
              </Modal>
              <Button
                css={css`
                  right: 10px;
                  position: absolute;
                `}
                shape="circle"
                danger
                onClick={openDeleteModel}
                icon={<DeleteOutlined />}
              />
            </div>
          )}
        </div>
      }
    >
      {appValue === 'work' && (
        <div>
          <strong>Job Beschreibung</strong>
          <span
            css={css`
              margin-left: 10px;
            `}
          >
            {userData && userData.jobdescription}
          </span>
        </div>
      )}
      <Descriptions title={item.name} column={1} bordered={false}>
        {/* {item.date && (
          <Descriptions.Item label="Datum">{item.date}</Descriptions.Item>
        )} */}
        <Descriptions.Item label="Kategorie">
          {appValue === 'nachbarschaft' &&
            item.categories.map((tag, index) => {
              return (
                <Tag
                  key={index}
                  css={css`
                    margin-top: 5px;
                    margin-top: 5px;
                    padding: 6px;
                    font-size: 14px;
                    font-weight: 800;
                  `}
                  color={categories[tag] ? categories[tag].color : appColor}
                >
                  {categories[tag] ? categories[tag].text : 'Text'}
                </Tag>
              );
            })}
          {appValue === 'work' &&
            item.categories.map((tag, index) => {
              return (
                <Tag
                  key={index}
                  css={css`
                    margin-top: 5px;
                    margin-top: 5px;
                    padding: 6px;
                    font-size: 14px;
                    font-weight: 800;
                  `}
                  color={
                    categoriesWork[tag] ? categoriesWork[tag].color : appColor
                  }
                >
                  {categoriesWork[tag] ? categoriesWork[tag].text : 'Text'}
                </Tag>
              );
            })}
        </Descriptions.Item>
        <Descriptions.Item>
          <Button
            css={css`
              margin-top: 10px;
              background-color: ${appColor};
              border-color: ${appColor};
              width: 100px;
              height: 40px;
              padding: 6.4px 17px !important;
              font-size: 16px;
              border-radius: 2px;
            `}
            onClick={() => {
              if (typeof window !== 'undefined') {
                window.localStorage.setItem('uid', item.user.uid);
                navigate('/app/user/');
              }
            }}
          >
            zum Profil
          </Button>
        </Descriptions.Item>

        {otherProps.highlight === 'true' && (
          <>
            <Descriptions.Item label="Beschreibung">
              {item.description}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:$${item.user.email}`}
              >
                {item.user.email}
              </a>
            </Descriptions.Item>
            {item.user.tel ||
              (user.tel && (
                <Descriptions.Item label="Telefon">
                  {item.user.uid === user.uid && user.tel && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`tel:$${user.tel}`}
                    >
                      {user.tel}
                    </a>
                  )}
                  {item.user.tel && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`tel:$${item.user.tel}`}
                    >
                      {item.user.tel}
                    </a>
                  )}
                </Descriptions.Item>
              ))}
          </>
        )}
      </Descriptions>
    </Card>
  );
};

export default CardItem;
