import React from 'react';
import { navigate } from 'gatsby';
import { css } from '@emotion/core';
import FullWidthBox from 'components/FullWidthBox';
import ExternalLink from 'components/ExternalLink';
import { Avatar, Divider, Spin } from 'antd';
import firebase from 'gatsby-plugin-firebase';
import { UserOutlined } from '@ant-design/icons';

let userId, storage;
const Profile = () => {
  const [userData, setUserLocal] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [imageUrl, setImageAsUrl] = React.useState('');
  if (typeof window !== 'undefined') {
    userId = window.localStorage.getItem('uid');
    // window.localStorage.removeItem('uid');
  }

  if (!userId) {
    navigate('/app/map/');
  }

  const updateUserData = () => {
    if (userId) {
      firebase
        .firestore()
        .collection('usersData')
        .where('uid', '==', userId)
        .get()
        .then((snapshot) => {
          const userDataFromApi = snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          });
          setUserLocal({
            ...userDataFromApi[0],
            tel: userDataFromApi[0].tel,
            address: userDataFromApi[0].address,
            age: userDataFromApi[0].age,
          });
          setImageAsUrl(userDataFromApi[0].photoURL);
          setIsLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      storage = firebase.storage();

      // loadGoogleMaps(() => {});

      if (!userData) {
        updateUserData();
      }
    }
  });

  return (
    <FullWidthBox
      css={css`
        height: 100vh;
        margin-top: 100px;
      `}
    >
      <article
        css={css`
          height: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            // flex-direction: column;
          `}
        >
          {imageUrl ? (
            <Avatar
              css={css`
                .ant-avatar-string {
                  position: unset;
                }
              `}
              size={64}
              src={imageUrl}
            />
          ) : (
            <Avatar size={64} icon={<UserOutlined />} />
          )}
        </div>
        <Divider />
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <div>
            <Spin
              spinning={isLoading}
              css={css`
                width: 100%;
                div {
                  width: 100%;
                }
              `}
            >
              {userData && (
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <div>
                    <strong>Name: </strong>
                    <span
                      css={css`
                        margin-left: 15px;
                      `}
                    >
                      {userData.displayName}
                    </span>
                  </div>
                  <div>
                    <strong>Email: </strong>
                    <span
                      css={css`
                        margin-left: 15px;
                      `}
                    >
                      <ExternalLink href={`mailto:${userData.email}`}>
                        {userData.email}
                      </ExternalLink>
                    </span>
                  </div>
                  {userData.phoneNumber && (
                    <div>
                      <strong>Telefon: </strong>
                      <span
                        css={css`
                          margin-left: 15px;
                        `}
                      >
                        {userData.phoneNumber}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </Spin>
          </div>
        </div>
      </article>
    </FullWidthBox>
  );
};

export default Profile;
