import React from 'react';
import { navigate } from 'gatsby';
import { getUser, setUser } from 'utils/auth';
import { css } from '@emotion/core';
import FullWidthBox from 'components/FullWidthBox';
import {
  Avatar,
  Divider,
  Typography,
  InputNumber,
  Button,
  Form,
  Input,
  Spin,
  Modal,
  Upload,
  Progress,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import firebase from 'gatsby-plugin-firebase';
import { AppStateContext } from 'state/appstate';

let storage;
const Profile = () => {
  const [form] = Form.useForm();
  const [user, setUserLocal] = React.useState(getUser());
  const [modalState, setModalState] = React.useState(false);
  const [loadingDeleteUser, setLoadingDeleteUser] = React.useState(false);
  const [userData, setUserData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [imageAsFile, setImageAsFile] = React.useState();
  const [imageAsUrl, setImageAsUrl] = React.useState();
  const { getAppColors } = React.useContext(AppStateContext);
  const appColor = getAppColors();
  const { Title } = Typography;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const validateMessages = {
    required: 'TDas ist ein Pflichtfeld!',
    types: {
      email: 'Keine valide Email!',
      number: 'Keine valide Nummer!',
    },
  };

  const codeAddress = (address, callback) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status == 'OK') {
        const pos = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        callback(pos);
      }
    });
  };
  const loadGoogleMaps = (callback) => {
    const existingScript = document.getElementById('googleMaps');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3CdFFztYBRkJlxlazc1yp7K3ym7ZDxB0&libraries=geometry`;
      script.id = 'googleMaps';
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  const updateUserData = () => {
    firebase
      .firestore()
      .collection('usersData')
      .where('uid', '==', user.uid)
      .get()
      .then((snapshot) => {
        const userDataFromApi = snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
        setUserLocal({
          ...user,
          ...userDataFromApi[0],
          tel: userDataFromApi[0].tel,
          address: userDataFromApi[0].address,
          age: userDataFromApi[0].age,
          jobdescription: userDataFromApi[0].jobdescription,
        });
        setImageAsUrl(userDataFromApi[0].photoURL);
        setUserData(userDataFromApi[0]);
        setIsLoading(false);
        // user.displayName = userDataFromApi
        setUser({
          ...user,
          ...userDataFromApi[0],
          tel: userDataFromApi[0].tel,
          address: userDataFromApi[0].address,
          age: userDataFromApi[0].age,
        });
        form.resetFields();
      });
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      storage = firebase.storage();

      loadGoogleMaps(() => {});

      if (!userData) {
        updateUserData();
      }
    }
  });

  const onFinish = (values) => {
    setIsLoading(true);
    codeAddress(values.user.address, (pos) => {
      // console.log(values);
      const userDataToUpdate = {
        displayName: values.user.name ? values.user.name : user.displayName,
      };
      if (typeof window !== 'undefined') {
        firebase
          .auth()
          .currentUser.updateProfile(userDataToUpdate)
          .then(() => {
            // Update successful.
            const userMetaDataToUpdate = {
              id: userData.id,
              displayName: values.user.name
                ? values.user.name
                : user.displayName,
              email: values.user.email ? values.user.email : user.email,
              tel: values.user.tel ? values.user.tel : '',
              position: pos,
              age: values.user.age,
              jobdescription: values.user.jobdescription,
              address: values.user.address
                ? values.user.address
                : userData.address,
            };
            firebase
              .firestore()
              .collection('usersData')
              .doc(userMetaDataToUpdate.id)
              .update(userMetaDataToUpdate)
              .then(() => {
                firebase
                  .auth()
                  .currentUser.updateEmail(
                    values.user.email ? values.user.email : user.email,
                  )
                  .then(() => {
                    // Update successful.
                    setIsLoading(false);
                  })
                  .catch(() => {
                    // An error happened.
                  });
                updateUserData();
              });
          })
          .then(() => {})
          .catch(() => {
            // console.log(error);
          });
      }
    });
  };

  const deleteUser = () => {
    setLoadingDeleteUser(true);
    if (typeof window !== 'undefined') {
      const userToDelete = firebase.auth().currentUser;

      firebase
        .firestore()
        .collection('usersData')
        .doc(userData.id)
        .delete()
        .then(() => {
          userToDelete
            .delete()
            .then(() => {
              setUser({});
              setLoadingDeleteUser(false);
              navigate('/');
            })
            .catch(() => {
              // An error happened.
            });
        })
        .catch(() => {});
    }
  };

  const [progress, setProgress] = React.useState();
  const handleFireBaseUpload = (e) => {
    if (imageAsFile === '') {
      return;
    }
    let uploadTask;
    if (progress === undefined) {
      uploadTask = storage
        .ref(`/user/${user.uid}/${e.file.originFileObj.name}`)
        .put(e.file.originFileObj);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progressPercent =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (progressPercent === 100) {
            setProgress(undefined);
          } else {
            setProgress(progressPercent);
          }
          // console.log(snapshot);
          // totalBytes
          // bytesTransferred
        },
        (err) => {
          //catches the errors
          console.log(err);
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref('user')
            .child(`${user.uid}/${e.file.originFileObj.name}`)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              // setImageAsFile(e.file.originFileObj);
              setImageAsUrl(fireBaseUrl);
              const currentUser = firebase.auth().currentUser;
              currentUser
                .updateProfile({
                  photoURL: fireBaseUrl,
                })
                .then(() => {
                  firebase
                    .firestore()
                    .collection('usersData')
                    .doc(userData.id)
                    .update({
                      photoURL: fireBaseUrl,
                    });
                })
                .catch(() => {
                  // An error happened.
                });
            });
        },
      );
    }
  };
  // console.log(user);
  return (
    <FullWidthBox
      css={css`
        height: 100vh;
        margin-top: 100px;
      `}
    >
      <article
        css={css`
          height: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <Upload
            name="avatar"
            css={css`
              display: flex;
              justify-content: center;
              width: auto;
              .ant-upload {
                display: table;
                float: left;
                width: unset;
                height: unset;
                margin-right: 0;
                margin-bottom: 0;
                text-align: center;
                vertical-align: top;
                background-color: #fafafa;
                border: 1px dashed #d9d9d9;
                border-radius: 35px;
                cursor: pointer;
                transition: border-color 0.3s ease;
                span {
                  padding: 0 !important;
                }
              }
            `}
            previewFile={imageAsFile ? imageAsFile.originFileObj : ''}
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            // beforeUpload={beforeUpload}
            onChange={handleFireBaseUpload}
          >
            {imageAsUrl ? (
              <Avatar
                css={css`
                  .ant-avatar-string {
                    position: unset;
                  }
                `}
                size={64}
              >
                <img src={imageAsUrl} alt="avatar" style={{ width: '100%' }} />
              </Avatar>
            ) : (
              <Avatar size={64} icon={<UserOutlined />} />
            )}
          </Upload>

          <div
            css={css`
              display: flex;
              justify-content: center;
              flex-direction: column;
              margin-left: 10px;
            `}
          >
            <Title
              css={css`
                margin-bottom: 5px !important;
              `}
              level={1}
            >{`${user.displayName}`}</Title>
            <p>{user.address}</p>
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          {progress && (
            <Progress
              css={css`
                width: 30%;
                display: flex;
                justify-content: center;
              `}
              percent={parseInt(progress, 10)}
            />
          )}
        </div>
        <Divider />
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <Form
            {...layout}
            layout="horizontal"
            name="nest-messages"
            onFinish={onFinish}
            form={form}
            validateMessages={validateMessages}
            css={css`
              width: 100%;
            `}
            initialValues={{
              user: {
                name: user.displayName,
                email: user.email,
                tel: user.tel,
                address: user.address,
                age: user.age,
                jobdescription: user.jobdescription,
              },
            }}
          >
            <Spin
              spinning={isLoading}
              css={css`
                width: 100%;
                div {
                  width: 100%;
                }
              `}
            >
              <Form.Item
                name={['user', 'name']}
                label="Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['user', 'email']}
                label="Email"
                rules={[{ type: 'email', required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['user', 'tel']}
                label="Telefon"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name={['user', 'address']}
                label="Adresse"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name={['user', 'age']} label="Age">
                <InputNumber />
              </Form.Item>
              <Form.Item
                name={['user', 'jobdescription']}
                label="Job Beschreibung"
                rules={[{ required: false }]}
              >
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 19 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  css={css`
                    background-color: ${appColor};
                    border-color: ${appColor};
                  `}
                >
                  Speichern
                </Button>
              </Form.Item>
            </Spin>
          </Form>
        </div>
        <Divider />
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <Modal
            title="Account löschen"
            visible={modalState}
            onOk={deleteUser}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setModalState(false);
                }}
              >
                Abbrechen
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loadingDeleteUser}
                onClick={deleteUser}
              >
                Löschen
              </Button>,
            ]}
          >
            <p>
              Wollen Sie Ihren Account wirklich l&ouml;schen? Wir k&ouml;nnen
              ihre Daten nicht mehr herstellen!
            </p>
          </Modal>
          <Button
            onClick={() => {
              setModalState(true);
            }}
            type="secondary"
          >
            Account löschen
          </Button>
        </div>
      </article>
    </FullWidthBox>
  );
};

export default Profile;
