import React from 'react';
import { navigate, Link } from 'gatsby';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import FullWidthBox from 'components/FullWidthBox';
import { setAndUpdateUser, setAndUpdateUserFromAuthState } from '../utils/auth';
import firebase from 'gatsby-plugin-firebase';
import { Row, Col, Typography, Checkbox } from 'antd';
import { css } from '@emotion/core';
import { AppStateContext } from 'state/appstate';

const Login = () => {
  // const { Title } = Typography;
  const [checkboxValue, setCheckbox] = React.useState(false);
  const { getAppColors } = React.useContext(AppStateContext);
  const appColor = getAppColors();
  let getUiConfig;
  if (typeof window !== 'undefined') {
    // StyledFirebaseAuth = require('react-firebaseui/StyledFirebaseAuth');

    getUiConfig = (auth) => {
      return {
        signInFlow: 'popup',
        signInOptions: [
          auth.EmailAuthProvider.PROVIDER_ID,
          auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
          signInSuccessWithAuthResult: (result) => {
            setAndUpdateUser(result.user);
            setAndUpdateUserFromAuthState(result.user);
            navigate('/app/home');
          },
        },
      };
    };
  }
  // console.log('ssf');
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const privacy = window.localStorage.getItem('privacy');

      if (privacy) {
        setCheckbox(privacy === 'approved' || false);
        // window.localStorage.removeItem('privacy');
      }
    }
  });
  const changeValue = (value) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(
        'privacy',
        value.target.checked ? 'approved' : 'declined',
      );
      setCheckbox(value.target.checked);
    }
  };
  return (
    <FullWidthBox
      css={css`
        height: 100vh;
        padding-top: 126px;
      `}
    >
      <article
        css={css`
          height: 100%;
        `}
      >
        <Row
          gutter={16}
          css={css`
            // margin-bottom: 100px;
            height: 100%;
          `}
        >
          <Col
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              .firebaseui-idp-button,
              .firebaseui-idp-password,
              .firebaseui-idp-password:hover,
              .mdl-button.firebaseui-idp-password:active,
              .mdl-button.firebaseui-idp-password:focus {
                background-color: #fff !important;
                border-color: ${appColor} !important;
                border: 1px solid ${appColor} !important;
              }
              .mdl-button.firebaseui-idp-password .firebaseui-idp-icon {
                background-color: ${appColor} !important;
              }
              .firebaseui-idp-text {
                color: ${appColor} !important;
              }
            `}
            span={24}
            xs={24}
            sm={24}
          >
            <p>
              <Checkbox
                css={css`
                  margin-right: 20px !important;
                `}
                checked={checkboxValue}
                onChange={changeValue}
              />
              Ich akzeptiere die{' '}
              <Link target="_blank" to="datenschutzerklaerung">
                Datenschutzerklärung
              </Link>{' '}
              von Togethere.is
            </p>

            {checkboxValue && (
              <>
                <p>Bitte loggen Sie sich ein:</p>
                {typeof window !== `undefined` && (
                  <StyledFirebaseAuth
                    uiConfig={getUiConfig(firebase.auth)}
                    firebaseAuth={firebase.auth()}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </article>
    </FullWidthBox>
  );
};

export default Login;
