import React from 'react';
import { getUser } from '../utils/auth';
import { css } from '@emotion/core';
import { Layout, Button, Modal, Form, Input, Select, message } from 'antd';
import moment from 'moment';
import TogethereMap from 'components/TogethereMap';
import { EnvironmentOutlined, PlusOutlined } from '@ant-design/icons';
import { media } from 'utils/media';
import firebase from 'gatsby-plugin-firebase';
import { categories, categoriesWork, categoriesSeelsorge } from 'utils/helpers';
import { AppStateContext } from 'state/appstate';

const TogethereMapWrapper = () => {
  const user = getUser();
  const [form] = Form.useForm();
  const [reload, setReloadMap] = React.useState(false);
  const [modalState, setModalState] = React.useState(false);
  const { appValue, getAppColors } = React.useContext(AppStateContext);
  const appColor = getAppColors();
  const onAddButton = () => {
    setModalState(true);
  };
  const { Option } = Select;

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const codeAddress = (address, callback) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status == 'OK') {
        const pos = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        callback(pos);
      }
    });
  };

  const successAddProject = () => {
    message.success('The project was successfully created.');
  };
  const onLocateUser = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (positionGeo) => {
          const pos = {
            lat: positionGeo.coords.latitude,
            lng: positionGeo.coords.longitude,
          };
          window.localStorage.setItem('searchPosition', JSON.stringify(pos));
          window.location.reload();
        },
        () => {
          // handleLocationError(true, map.getCenter());
        },
      );
    }
  };

  const onAddProject = (values) => {
    values.project.date = moment(values.date).format();
    // values.project.place = positionState;

    codeAddress(values.project.address, (pos) => {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem('searchPosition', JSON.stringify(pos));
      }
      values.project.position = pos;
      values.project.createdAt = moment().format();
      values.project.mode = appValue;
      values.project.user = {
        uid: user.uid,
        position: '',
        favoriteCategories: [],
        roles: ['user'],
        intro: false,
        displayName: user.displayName ? user.displayName : '',
        email: user.email ? user.email : '',
        phoneNumber: user.phoneNumber ? user.phoneNumber : '',
        photoURL: user.photoURL ? user.photoURL : '',
        lastSignInTime:
          user && user.metadata && user.metadata.lastSignInTime
            ? user.metadata.lastSignInTime
            : '',
        creationTime:
          user && user.metadata && user.metadata.creationTime
            ? user.metadata.creationTime
            : '',
      };
      setModalState(false);
      if (typeof window !== 'undefined') {
        firebase
          .firestore()
          .collection('projects')
          .add(values.project)
          .then(() => {
            successAddProject();
            setReloadMap(!reload);
            form.resetFields();
          });
      }
    });
  };
  const validateMessages = {
    required: 'This field is required!',
    types: {
      email: 'Not a validate email!',
      number: 'Not a validate number!',
    },
    number: {
      range: 'Must be between ${min} and ${max}',
    },
  };

  const [mode, setMode] = React.useState();
  const onChangeMode = (value) => {
    setMode(value);
  };

  return (
    <Layout
      css={css`
        background: #ffffff;
        .ant-layout-sider {
          background: #ffffff;
        }

        #components-layout-demo-custom-trigger .trigger {
          font-size: 18px;
          line-height: 64px;
          padding: 0 24px;
          cursor: pointer;
          transition: color 0.3s;
        }

        #components-layout-demo-custom-trigger .trigger:hover {
          color: #1890ff;
        }

        #components-layout-demo-custom-trigger .logo {
          height: 32px;
          background: rgba(255, 255, 255, 0.2);
          margin: 16px;
        }

        .site-layout .site-layout-background {
          background: #ffffff;
        }
        .toggleProjectList {
          position: relative;
          span {
            position: absolute;
            left: 100%;
            z-index: 9;
            font-size: 37px;
            background: #ffffff;
          }
        }
      `}
    >
      <TogethereMap reload={reload} />
      <Modal
        title="Dienst anbieten"
        visible={modalState}
        onOk={onAddButton}
        onCancel={() => {
          setModalState(false);
        }}
        footer={false}
      >
        <Form
          {...layout}
          form={form}
          layout="horizontal"
          name="nest-messages"
          onFinish={onAddProject}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={['project', 'name']}
            label="Name"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['project', 'address']}
            label="Adresse"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['project', 'mode']}
            label="Mode"
            rules={[
              {
                required: true,
                message: 'Bitte wähle Privat oder Business aus!',
              },
            ]}
          >
            <Select
              onChange={onChangeMode}
              placeholder="Bitte wähle Privat oder Business aus"
            >
              <Option value="nachbarschaft">Privat</Option>
              <Option value="work">Business</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={['project', 'categories']}
            label="Kategorie"
            rules={[
              {
                required: true,
                message: 'Bitte wähle eine Kategorie aus!',
                type: 'array',
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Bitte wähle eine Kategorie aus."
            >
              {mode === 'nachbarschaft' &&
                Object.keys(categories).map((key, index) => {
                  return (
                    <Option key={index} value={categories[key].value}>
                      {categories[key].text}
                    </Option>
                  );
                })}
              {mode === 'work' &&
                Object.keys(categoriesWork).map((key, index) => {
                  return (
                    <Option key={index} value={categoriesWork[key].value}>
                      {categoriesWork[key].text}
                    </Option>
                  );
                })}
              {mode === 'seelsorge' &&
                Object.keys(categoriesSeelsorge).map((key, index) => {
                  return (
                    <Option key={index} value={categoriesSeelsorge[key].value}>
                      {categoriesSeelsorge[key].text}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name={['project', 'description']} label="Beschreibung">
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 16 }}>
            <Button
              css={css`
                margin-left: 10px;
              `}
              type="primary"
              htmlType="submit"
            >
              Absenden
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Button
        css={css`
          position: absolute;
          bottom: 150px;
          margin-left: auto;
          margin-right: auto;
          right: 50px;
          // right: 0;
          background-color: ${appColor};
          border-color: ${appColor};
          width: 60px !important;
          height: 60px !important;
          font-size: 26px !important;
          // @media ${media.small} {
          //   left: 50%;
          // }
        `}
        onClick={onLocateUser}
        shape="circle"
        type="primary"
        icon={<EnvironmentOutlined />}
        size="large"
      />
      <Button
        css={css`
          position: absolute;
          bottom: 50px;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          background-color: ${appColor};
          border-color: ${appColor};
          width: 60px !important;
          height: 60px !important;
          font-size: 26px !important;
          @media ${media.small} {
            left: 25%;
          }
        `}
        onClick={onAddButton}
        shape="circle"
        type="primary"
        icon={<PlusOutlined />}
        size="large"
      />
    </Layout>
  );
};

export default TogethereMapWrapper;
