import React from 'react';
import { navigate } from 'gatsby';
import { css } from '@emotion/core';
import firebase from 'gatsby-plugin-firebase';
import CardItem from 'components/CardItem';
import { Layout, Skeleton, Button, Spin, Select, Input, Carousel } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import GoogleMapReact from 'google-map-react';
// import { googleMapsOptions } from 'utils/googleMaps';
import { media } from 'utils/media';
import { categories, categoriesWork, categoriesSeelsorge } from 'utils/helpers';
import { AppStateContext } from 'state/appstate';

const { Sider } = Layout;
const { Option } = Select;
const { Search } = Input;
const TogetherMap = () => {
  const [projectList, setProjects] = React.useState(undefined);
  // let originalProjectList;
  const [originalProjectList, setOriginalProjects] = React.useState(undefined);
  const [highlightedProject, setHighlightedProject] = React.useState();
  const [radius] = React.useState(4000);
  const [activeCardItems, setActiveCardItems] = React.useState(false);
  // const [filterValue, setFilter] = React.useState();
  const [zoom, setZoom] = React.useState(4);
  const [isLoading, setIsLoading] = React.useState(true);
  const [mapCenter, setMapCenter] = React.useState({
    lat: 47.7253924,
    lng: 10.3143271,
  });
  const [mapObj, setMapObj] = React.useState();
  const { appValue, getAppColors } = React.useContext(AppStateContext);

  const appColor = getAppColors();

  const slider = React.useRef();

  const setUserAndProjects = React.useCallback(
    (projects, pos, filterValue) => {
      if (pos) {
        projects = projects.filter((project) => {
          return (
            google.maps.geometry.spherical.computeDistanceBetween(
              new google.maps.LatLng(pos.lat, pos.lng),
              new google.maps.LatLng(
                project.position.lat,
                project.position.lng,
              ),
            ) <= 4000
          );
        });
      }
      console.log(filterValue);
      if (filterValue) {
        projects = projects.filter((project) => {
          return project.categories.some((r) => filterValue.includes(r));
        });
      }
      if (filterValue && filterValue.length === 0) {
        projects = originalProjectList;
      }

      if (projects) {
        setProjects(projects);
        setIsLoading(false);
      } else {
        setProjects([]);
        setIsLoading(false);
      }
    },
    [originalProjectList],
  );

  React.useEffect(() => {
    const locateUser = (projects) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (positionGeo) => {
            const pos = {
              lat: positionGeo.coords.latitude,
              lng: positionGeo.coords.longitude,
            };

            const categoryFilter = window.localStorage.getItem('category');

            setMapCenter(pos);
            setZoom(13);
            setUserAndProjects(
              projects,
              pos,
              categoryFilter ? categoryFilter : false,
            );
            window.localStorage.setItem('searchPosition', JSON.stringify(pos));
          },
          () => {
            // handleLocationError(true, map.getCenter());
          },
        );
      } else {
        // Browser doesn't support Geolocation
        // handleLocationError(false, map.getCenter());
      }
    };

    if (!projectList) {
      if (typeof window !== 'undefined') {
        if (appValue) {
          firebase
            .firestore()
            .collection('projects')
            .where('mode', '==', appValue)
            .get()
            .then((snapshot) => {
              const projects = snapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  id: doc.id,
                };
              });
              const pos = JSON.parse(
                window.localStorage.getItem('searchPosition'),
              );
              const categoryFilter = window.localStorage.getItem('category');
              if (!originalProjectList) {
                setOriginalProjects(projects);
              }
              if (pos) {
                setMapCenter(pos);
                setZoom(13);
                setUserAndProjects(
                  projects,
                  pos,
                  categoryFilter ? categoryFilter : false,
                );
              } else {
                locateUser(projects);
              }
            });
        }
      }
    }

    return () => {};
  }, [projectList, setUserAndProjects, originalProjectList, appValue]);

  const ProjectCircleWrapper = (props) => {
    return (
      <div
        className={props.highlight === 'true' ? 'yes' : 'no'}
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          width: 4%;
          height: 4%;
          min-height: 20px;
          min-width: 20px;
          background-color: ${appColor};
          border-radius: 100%;
          user-select: none;
          border: ${props.highlight === 'true' ? '2px solid #1d1d1b' : '0'};
          transform: translate(-50%, -50%);
          &:hover {
            z-index: 1;
          }
        `}
      />
    );
  };

  const codeAddress = (address) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status == 'OK') {
        const pos = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        setMapCenter(pos);
        setZoom(13);
        setUserAndProjects(originalProjectList, pos);
      }
    });
  };

  const getCirclePoints = (center, radius, numPoints, clockwise) => {
    const points = [];
    for (let i = 0; i < numPoints; ++i) {
      let angle = (i * 360) / numPoints;
      if (!clockwise) {
        angle = 360 - angle;
      }

      // the maps API provides geometrical computations
      // just make sure you load the required library (libraries=geometry)
      const p = google.maps.geometry.spherical.computeOffset(
        center,
        radius,
        angle,
      );
      points.push(p);
    }

    // 'close' the polygon
    points.push(points[0]);
    return points;
  };

  const [donutMapMarker, setDonutMapMarker] = React.useState();

  const sepMapMarkers = (map) => {
    if (google) {
      const donut = new google.maps.Polygon({
        paths: [
          getCirclePoints(
            new google.maps.LatLng(mapCenter.lat, mapCenter.lng),
            radius,
            300,
            true,
          ),
          getCirclePoints(
            new google.maps.LatLng(mapCenter.lat, mapCenter.lng),
            400,
            300,
            false,
          ),
        ],
        strokeColor: '#36679c',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#36679c',
        fillOpacity: 0.3,
      });
      donut.setMap(map);
      setDonutMapMarker(donut);
    }
  };

  const handleApiLoaded = (map, maps) => {
    setMapObj(map);
    sepMapMarkers(map);
  };

  const onChildClick = (key, childProps) => {
    setHighlightedProject(childProps.project.id);
    slider.current.goTo(childProps.index);
    setActiveCardItems(true);
  };

  const onChangeMap = () => {
    if (mapObj) {
      donutMapMarker.setMap(null);
      sepMapMarkers(mapObj);
    }
  };

  const onClickItem = (project, index) => {
    setHighlightedProject(project.id);
    slider.current.goTo(index);
  };
  const onChangeCategory = (value) => {
    setUserAndProjects(originalProjectList, mapCenter, value);
  };
  const onChangeSearch = (value) => {
    codeAddress(value);
    setUserAndProjects(originalProjectList, mapCenter, value);
  };

  const ProjectCircle = (props) => (
    <ProjectCircleWrapper {...props} alt={props.text} />
  );
  let defaultCat;
  const objectCat = {};
  if (typeof window !== 'undefined') {
    defaultCat = window.localStorage.getItem('category')
      ? window.localStorage.getItem('category')
      : '';

    // if(categories[defaultCat]) {

    // }
    if (defaultCat) {
      objectCat.defaultValue = [defaultCat];
    }
  }

  return (
    <>
      <Select
        size="large"
        {...objectCat}
        onDeselect={() => {
          if (typeof window !== 'undefined') {
            window.localStorage.removeItem('category');
          }
        }}
        css={css`
          position: absolute;
          top: 6.6rem;
          @media ${media.small} {
            top: 3.75rem;
          }
          left: 0;
          z-index: 99;
          // display: none;
          width: 50%;
          .ant-select-selector {
            background: #ffffff;
            border-width: 1px;
            border-color: ${appColor};
            // border-top: 0;
            border-left: 0;
            // border-right: 0;
          }
          .ant-select-selection-placeholder {
            color: #1d1d1b;
            font-weight: 500;
          }
          .ant-select-selector:hover,
          .ant-select-selector:focus {
            border-color: #1d1d1b !important;
          }
          @media ${media.small} {
            display: block;
            left: 400px;
            width: 250px;
          }
        `}
        onChange={onChangeCategory}
        mode="multiple"
        placeholder="Bitte wähle eine Kategorie aus"
      >
        {appValue === 'nachbarschaft' &&
          Object.keys(categories).map((key, index) => {
            return (
              <Option key={index} value={categories[key].value}>
                {categories[key].text}
              </Option>
            );
          })}
        {appValue === 'work' &&
          Object.keys(categoriesWork).map((key, index) => {
            return (
              <Option key={index} value={categoriesWork[key].value}>
                {categoriesWork[key].text}
              </Option>
            );
          })}
        {appValue === 'seelsorge' &&
          Object.keys(categoriesSeelsorge).map((key, index) => {
            return (
              <Option key={index} value={categoriesSeelsorge[key].value}>
                {categoriesSeelsorge[key].text}
              </Option>
            );
          })}
      </Select>
      <Search
        placeholder="Ort eingeben"
        enterButton="Suchen"
        size="large"
        onSearch={onChangeSearch}
        css={css`
          position: absolute;
          top: 6.6rem;
          @media ${media.small} {
            top: 3.75rem;
          }
          left: 50%;
          z-index: 99;
          // display: none;
          width: 50%;
          @media ${media.small} {
            left: 650px;
            width: 250px;
            display: block;
          }
          button {
            background-color: ${appColor};
            border-color: ${appColor};
          }
          input {
            background: #ffffff;
            border-width: 1px;
            border-color: ${appColor};
            // border-top: 0;
            border-left: 0;
            border-right: 0;
            :hover,
            :focus {
              border-color: #1d1d1b;
            }
            ::placeholder {
              color: #1d1d1b;
              font-weight: 500;
            }
          }
        `}
      />
      <Button
        css={css`
          @media ${media.small} {
            top: 3.75rem;
          }
          position: absolute;
          // background-color: ${appColor};
          // border-color: ${appColor};
          top: 8.8rem;
          /* left: 60%; */
          right: 0;
          z-index: 99;
          height: 40px;
          padding: 6.4px 17px !important;
          font-size: 16px;
          border-radius: 2px;
          @media ${media.small} {
            left: 900px;
            // width: 250px;
            display: block;
          }
        `}
        onClick={() => {
          navigate('/app/home/');
        }}
      >
        Zurück
      </Button>
      <Sider
        className="site-layout-background"
        trigger={null}
        collapsible
        collapsedWidth="0"
        css={css`
          display: none;
          margin-top: 3.75rem;
          @media ${media.small} {
            display: block;
            max-width: 400px !important;
            min-width: 400px !important;
            width: 400px !important;
          }
        `}
      >
        <Spin
          css={css`
            margin-top: 100px;
          `}
          spinning={isLoading}
        >
          {projectList === 0 && (
            <Skeleton
              css={css`
                h3 {
                  margin-left: 1rem;
                }
                ul {
                  margin-right: 1rem;
                }
                h3,
                li {
                  background: ${appColor} !important;
                }
              `}
            />
          )}
          {projectList &&
            projectList.map((project, index) => {
              return (
                <CardItem
                  highlight={
                    highlightedProject === project.id ? 'true' : 'false'
                  }
                  key={index}
                  onClick={() => {
                    onClickItem(project);
                  }}
                  item={project}
                />
              );
            })}
        </Spin>
      </Sider>
      <Button
        css={css`
          position: absolute !important;
          bottom: 30%;
          height: auto;
          z-index: 2;
          right: 4px;
          background-color: ${appColor};
          border-color: ${appColor};
          font-size: 26px;
          display: ${activeCardItems ? 'block' : 'none'} !important;
          @media ${media.small} {
            display: none;
          }
        `}
        onClick={() => {
          setActiveCardItems(false);
        }}
      >
        <CloseCircleOutlined />
      </Button>
      <Carousel
        css={css`
          position: absolute !important;
          bottom: 0;
          width: 100%;
          height: 30%;
          z-index: 1;
          display: ${activeCardItems ? 'block' : 'none'} !important;
          @media ${media.small} {
            display: none !important;
          }
        `}
        ref={(ref) => {
          slider.current = ref;
        }}
      >
        {projectList &&
          projectList.map((project, index) => {
            return (
              <CardItem
                highlight={highlightedProject === project.id ? 'true' : 'false'}
                key={index}
                css={css`
                  margin: 0 !important;
                `}
                onClick={() => {
                  onClickItem(project, index);
                }}
                item={project}
              />
            );
          })}
      </Carousel>
      <div
        css={css`
          height: 100vh;
          width: 100%;
        `}
      >
        <GoogleMapReact
          // options={{
          //   styles: googleMapsOptions.styles,
          // }}
          css={css`
            min-height: 500px;
          `}
          id="googleMaps"
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          defaultZoom={4}
          zoom={zoom}
          defaultCenter={{ lat: 47.7253924, lng: 10.3143271 }}
          center={mapCenter}
          bootstrapURLKeys={{
            key: 'AIzaSyB3CdFFztYBRkJlxlazc1yp7K3ym7ZDxB0',
            libraries: ['geometry'],
          }}
          onChildClick={onChildClick}
          onChange={onChangeMap}
          yesIWantToUseGoogleMapApiInternals={true}
        >
          {projectList &&
            projectList.map((project, index) => {
              return (
                <ProjectCircle
                  key={index} //
                  highlight={
                    highlightedProject === project.id ? 'true' : 'false'
                  }
                  text="Name"
                  index={index}
                  project={project}
                  lat={project.position.lat}
                  lng={project.position.lng}
                />
              );
            })}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default TogetherMap;
