import React from 'react';
import { navigate } from 'gatsby';
import { css } from '@emotion/core';
import Intro from 'components/Intro';
import FullWidthBox from 'components/FullWidthBox';
import { isLoggedIn, getUser } from '../utils/auth';
import firebase from 'gatsby-plugin-firebase';

const Index = () => {
  const [user, setUserData] = React.useState(getUser());
  const uid = user.uid;

  if (!isLoggedIn()) {
    navigate('/login');
  }
  const getData = () => {
    if (typeof window !== 'undefined') {
      firebase.auth().onAuthStateChanged((userFromApi) => {
        if (userFromApi) {
          setUserData(userFromApi);
        }
      });
    }
  };

  React.useEffect(() => {
    getData();
  }, [uid]);
  return (
    <FullWidthBox
      css={css`
        height: 100vh;
        padding-top: 126px;
      `}
    >
      <article
        css={css`
          height: 100%;
        `}
      >
        <Intro />
      </article>
    </FullWidthBox>
  );
};
export default Index;
