/* eslint-disable complexity */
import React from 'react';
import { navigate } from 'gatsby';
import { getUser } from '../utils/auth';
import { css } from '@emotion/core';
import ExternalLink from 'components/ExternalLink';
import { Form, Input, Button, Select, Row, Col, Typography } from 'antd';
import {
  EnvironmentOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import firebase from 'gatsby-plugin-firebase';
import { categories, categoriesWork, categoriesSeelsorge } from 'utils/helpers';
import { AppStateContext } from 'state/appstate';

const { Title } = Typography;
const { Option } = Select;
const Intro = () => {
  const { appValue, getAppColors } = React.useContext(AppStateContext);
  const appColor = getAppColors();
  const user = getUser();
  let onFinish, setHelpSearchAtive, setHelpOfferhAtive, onAddProject;
  const [helpSearch, setHelpSearch] = React.useState(false);
  const [helpOffer, setHelpOffer] = React.useState(false);

  const loadGoogleMaps = (callback) => {
    const existingScript = document.getElementById('googleMaps');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB3CdFFztYBRkJlxlazc1yp7K3ym7ZDxB0&libraries=geometry`;
      script.id = 'googleMaps';
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  React.useEffect(() => {
    loadGoogleMaps(() => {});
  });

  if (typeof window !== 'undefined') {
    setHelpSearchAtive = () => {
      setHelpOffer(false);
      setHelpSearch(true);
    };
    setHelpOfferhAtive = () => {
      setHelpOffer(true);
      setHelpSearch(false);
    };

    const codeAddress = (address, callback) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status == 'OK') {
          const pos = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          callback(pos);
        }
      });
    };

    onAddProject = (values) => {
      codeAddress(values.address, (pos) => {
        window.localStorage.setItem('searchPosition', JSON.stringify(pos));
        values.position = pos;
        values.createdAt = moment().format();
        values.mode = appValue;
        values.user = {
          uid: user.uid,
          position: '',
          favoriteCategories: [],
          roles: ['user'],
          intro: false,
          displayName: user.displayName ? user.displayName : '',
          email: user.email ? user.email : '',
          phoneNumber: user.phoneNumber ? user.phoneNumber : '',
          photoURL: user.photoURL ? user.photoURL : '',
          lastSignInTime:
            user && user.metadata && user.metadata.lastSignInTime
              ? user.metadata.lastSignInTime
              : '',
          creationTime:
            user && user.metadata && user.metadata.creationTime
              ? user.metadata.creationTime
              : '',
        };
        firebase
          .firestore()
          .collection('projects')
          .add(values)
          .then(() => {
            navigate('/app/map/');
          });
      });
    };

    onFinish = (values) => {
      codeAddress(values.address, (pos) => {
        window.localStorage.setItem('searchPosition', JSON.stringify(pos));
        navigate('/app/map/');
      });
    };
  }
  return (
    <Row
      gutter={16}
      css={css`
      height: 100%;
      display
    `}
    >
      <Col
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        `}
        span={6}
        xs={24}
        sm={6}
      >
        <ExternalLink
          onClick={() => {
            setHelpSearch(true);
          }}
        >
          <Title level={2}>
            {(appValue === 'nachbarschaft' || appValue === undefined) && (
              <>Hilfe suchen</>
            )}
            {appValue === 'work' && <>Projekte suchen</>}
            {appValue === 'seelsorge' && <>Hilfe suchen</>}
          </Title>
        </ExternalLink>
        {helpSearch && (
          <Form
            css={css`
              width: 100%;
            `}
            className="animated fadeIn"
            name="need_help"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="address"
              rules={[
                { required: true, message: 'Bitte geb eine Adresse ein.' },
              ]}
            >
              <Input
                prefix={<EnvironmentOutlined className="site-form-item-icon" />}
                placeholder="Address"
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Suchen
              </Button>
            </Form.Item>
          </Form>
        )}
        <p
          css={css`
            color: #1d1d1b;
          `}
        >
          {(appValue === 'nachbarschaft' || appValue === undefined) && (
            <>
              Alle, die im Alltag Unterstützung brauchen, bspw. beim
              Hundeausführen, die ihre Einkäufe nicht selbst erledigen können
              oder Nachhilfeunterricht benötigen, können über die angebotenen
              Rubriken ihr Gesuch lokalbasiert aufgeben. bleibt gesund!
            </>
          )}
          {appValue === 'work' && (
            <>
              Ihr wollt ein Projekt umsetzen, wisst aber nicht, wie ihr das in
              diesen Zeiten gut bewerkstelligen könnt, da ihr nicht reisen
              könnt? Stellt eure Projekte ins Netz und findet Leute vor Ort, die
              diese Arbeit für euch erledigen können.
            </>
          )}
          {/* {appValue === 'seelsorge' && <>Hilfe suchen</>} */}
        </p>
      </Col>
      <Col
        xs={24}
        sm={12}
        // key={idx}
        className="gutter-row"
        span={12}
        css={css`
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-top: 30px;
          .grow {
            transition: all 0.2s ease-in-out;
          }
          .grow:hover {
            transform: scale(1.05);
          }
          .helpOffer {
            width: 80%;
            background: ${appColor};
            height: calc(80vw / 2 - 8px);
            border-radius: 80%;
            -moz-border-radius: 80%;
            -webkit-border-radius: 80%h;
            -o-border-radius: 80%;
            @media (max-width: 576px) {
              height: calc(140vw / 2 - 8px);
            }
          }
          .helpSearch {
            // position: absolute;
            display: flex;
            width: 20%;
            height: calc(100vw / 10 - 8px);
            background: #ffffff;
            border-radius: 20%;
            -moz-border-radius: 120%;
            -webkit-border-radius: 50%;
            -o-border-radius: 2%;
            @media (max-width: 576px) {
              height: calc(100vw / 5 - 8px);
            }
          }
        `}
      >
        <div
          className="helpOffer grow"
          onClick={setHelpOfferhAtive}
          onKeyPress={setHelpOfferhAtive}
        >
          <TeamOutlined
            css={css`
               {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                font-size: 2em;
                color: #ffffff;
              }
            `}
          />
        </div>
        <div
          onClick={setHelpSearchAtive}
          onKeyPress={setHelpSearchAtive}
          css={css`
            width: 100%;
            left: 0;
            right: 0;
            display: flex;
            position: absolute;
            justify-content: center;
          `}
        >
          <div className="helpSearch grow">
            <UserOutlined
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-size: 2em;
              `}
            />
          </div>
        </div>
      </Col>
      <Col
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        `}
        xs={24}
        sm={6}
        span={6}
      >
        <ExternalLink
          onClick={() => {
            setHelpOffer(true);
          }}
        >
          <Title level={2}>
            {(appValue === 'nachbarschaft' || appValue === undefined) && (
              <>Hilfe anbieten</>
            )}
            {appValue === 'work' && <>Projekte anbieten</>}
            {appValue === 'seelsorge' && <>Hilfe anbieten</>}
          </Title>
        </ExternalLink>
        {helpOffer && (
          <Form
            css={css`
              width: 100%;
            `}
            className="animated fadeIn"
            name="search_help"
            initialValues={{ remember: true }}
            onFinish={onAddProject}
          >
            <Form.Item
              name={['categories']}
              rules={[
                {
                  required: true,
                  message: 'Bitte wähle eine Kategorie aus!',
                  type: 'array',
                },
              ]}
            >
              <Select
                size="large"
                mode="multiple"
                placeholder="Bitte wähle eine Kategorie aus"
              >
                {appValue === 'nachbarschaft' &&
                  Object.keys(categories).map((key, index) => {
                    return (
                      <Option key={index} value={categories[key].value}>
                        {categories[key].text}
                      </Option>
                    );
                  })}
                {appValue === 'work' &&
                  Object.keys(categoriesWork).map((key, index) => {
                    return (
                      <Option key={index} value={categoriesWork[key].value}>
                        {categoriesWork[key].text}
                      </Option>
                    );
                  })}
                {appValue === 'seelsorge' &&
                  Object.keys(categoriesSeelsorge).map((key, index) => {
                    return (
                      <Option
                        key={index}
                        value={categoriesSeelsorge[key].value}
                      >
                        {categoriesSeelsorge[key].text}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              name="address"
              rules={[
                { required: true, message: 'Bitte gib eine Adresse ein.' },
              ]}
            >
              <Input
                size="large"
                prefix={
                  <EnvironmentOutlined
                    color="#1D1D1B"
                    className="site-form-item-icon"
                  />
                }
                placeholder="Adresse"
              />
            </Form.Item>
            <Form.Item
              name="description"
              rules={[
                { required: true, message: 'Bitte gib eine Beschreibung ein.' },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Hinzuf&uuml;gen
              </Button>
            </Form.Item>
          </Form>
        )}
        <div
          css={css`
            color: #1d1d1b;
          `}
        >
          {(appValue === 'nachbarschaft' || appValue === undefined) && (
            <>
              <p>
                Vielleicht trifft auch euch die Krise hart und ihr habt auf
                einmal Zeit, da ihr nicht zur Arbeit gehen könnt. Hier könnt ihr
                diese sinnvoll nutzen, in dem ihr andere Menschen, die nur noch
                sehr eingeschränkt agieren können, mit eurer Unterstützung den
                Alltag leichter macht bzw. überhaupt ermöglichen.
              </p>
              <p>
                Neben alltäglichen Erledigungen möchten wir auch
                Kreativschaffenden die Möglichkeit geben, Projekte anzutreiben
                und umzusetzen. Natürlich im Rahmen der geltenden Auflagen – und
                mit lokalen Experten vor Ort, die Anreisen unnötig machen.
              </p>
            </>
          )}
          {appValue === 'work' && (
            <p>
              Jeder Freelancer und Kreative, der aktuell durch eingefrorene
              Budgets oder Produktionsstops über freie Kapazitäten verfügt, kann
              seine Skills und Erfahrungen auf der Plattform teilen.
            </p>
          )}
          {/* {appValue === 'seelsorge' && <>Hilfe suchen</>} */}
        </div>
      </Col>
    </Row>
  );
};

export default Intro;
