import React from 'react';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';
import Profile from 'components/Profile';
import Intro from 'components/Intro';
import Home from 'components/Home';
import UserProfile from 'components/UserProfile';
import Login from 'components/Login';
import TogethereMapWrapper from 'components/TogethereMapWrapper';
import PrivateRoute from 'components/PrivateRoute';
import { notification, Button } from 'antd';
import { getUser } from 'utils/auth';

const App = () => {
  const user = getUser();
  const [isopen, setOpen] = React.useState(false);
  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => navigate('/app/profile/')}
    >
      Telefonnummer eintragen
    </Button>
  );
  const openNotification = () => {
    const args = {
      message: 'Telefonnummer eingeben.',
      description:
        'Wir haben gemerkt, dass wir von dir noch keine Telefonnummer anzeigen können. Gehe jetzt auf dein Profil und trage eine Telefonnummer ein, damit du besser erreichbar bist. Klick mich an!',
      duration: 3000,
      onClick: () => {
        navigate('/app/profile/');
      },
      btn,
    };
    notification.open(args);
    setOpen(true);
  };

  // React.useEffect(() => {
  //   if (!user.tel && !isopen) {
  //     openNotification();
  //   }
  // });
  return (
    <>
      {/* <Alert
        message="Warning"
        description="This is a warning notice about copywriting."
        type="warning"
        showIcon
      /> */}
      <Router>
        <PrivateRoute path="/app/profile" component={Profile} />
        <PrivateRoute path="/app/map" component={TogethereMapWrapper} />
        <PrivateRoute path="/app/intro" component={Intro} />
        <PrivateRoute path="/app/home" component={Home} />
        <PrivateRoute path="/app/user" component={UserProfile} />
        <Login path="/app/login" />
      </Router>
    </>
  );
};
export default App;
